import type { NuxtApp } from "#app";

const cookieTokenKey = "sanctum.token.cookie";
const tokenStorageKey = "sanctum.token.cookie";

// export const cookieTokenStorage = {
//   async get(app: NuxtApp) {
//     return app.runWithContext(() => {
//       const cookie = useCookie(cookieTokenKey, { readonly: true });
//       return unref(cookie.value) ?? undefined;
//     });
//   },

//   async set(app: NuxtApp, token?: string) {
//     await app.runWithContext(() => {
//       const cookie = useCookie(cookieTokenKey, {
//         secure: true,
//         maxAge: 60 * 60 * 24 * 365,
//         expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
//       });
//       cookie.value = token;
//     });
//   },
// };

const localTokenStorage = {
  get: async () => {
    if (import.meta.server) {
      return undefined;
    }

    return window.localStorage.getItem(tokenStorageKey) ?? undefined;
  },

  set: async (app: NuxtApp, token?: string) => {
    if (import.meta.server) {
      return;
    }

    if (!token) {
      window.localStorage.removeItem(tokenStorageKey);
      return;
    }

    window.localStorage.setItem(tokenStorageKey, token);
  },
};

export default defineAppConfig({
  ui: {
    icons: {
      dynamic: true,
    },
    container: {
      constrained: "max-w-6xl",
    },
  },
  sanctum: {
    tokenStorage: localTokenStorage,
  },
});
